import React from "react";
import { Icon } from "@iconify/react";
import "./index.css";
 
const WhatsApp = () => {
 return (
     <a className="whatsapp" rel="noreferrer" target='_blank' href="https://api.whatsapp.com/send?phone=18683998857&text=Hi NextCareerTT. I would like to jumpstart my job search!">
       <Icon icon="akar-icons:whatsapp-fill" width="30" color="white" />
     </a>
 );
};
 
export default WhatsApp;
