import React, { useEffect } from "react";
import "./index.css";
import SectionHeader from "../../components/SectionHeader";
import { Send, ArrowRight } from "react-feather";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const HowItWorksTwo = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    return (
        <div className="howitworks-two">
            <SectionHeader white title="How It Works" />
            <div data-aos="fade-up" className="howitworks-container">
                <div className="container relative">
                    <div className="howitworks-inner wrapper">
                        <div className="howitworks-text">
                            <h1>Take free evaluation now</h1>
                            <p>
                                Submit your current resume and cover letter
                                below, to receive professional feedback on the
                                areas in which you need to improve. <br />
                                <Link to="/howitworks">
                                    <span className="howitworks-button">
                                        Check our serices <ArrowRight />
                                    </span>
                                </Link>
                            </p>
                        </div>
                    </div>
                    <a
                        href="mailto:nextcareertt@gmail.com?subject=Evaluation Email&body=Upload Documents Here"
                        className="callta"
                    >
                        <span>Start</span>
                        <Send width="30" height="40" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default HowItWorksTwo;
