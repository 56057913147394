import emailjs from "emailjs-com";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { Check } from "react-feather";
import { motion } from "framer-motion";


const pageTransition = {
    out: {
        opacity: 0,
        y: "100%",
    },
    in: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1.6,
            ease: [0.6, 0.01, -0.05, 0.95],
        },
    },
    exit: {
        opacity: 0,
        y: "-100%",
        transition: {
            duration: 1,
            ease: [0.6, 0.01, -0.05, 0.95],
        },
    },
};

const pageTransitionFull = {
    out: {
        x: "100%",
        opacity: 0.6,
    },
    in: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.6,
            when: "beforeChildren",
        },
    },
    exit: {
        x: "100%",
        opacity: 0.6,
        transition: {
            duration: 1,
            when: "afterChildren",
        },
    },
};

const Result = () => {
    const success = useRef(null);
    useEffect(() => {
        setTimeout(() => {
            success.current.classList.add("close");
        }, 3000);
    }, [success]);
    return (
        <p ref={success} className="success-message">
            Your have successfully subscribed! <Check />
        </p>
    );
};

const FailedResult = () => {
    const fail = useRef(null);
    useEffect(() => {
        setTimeout(() => {
            fail.current.classList.add("close");
        }, 3000);
    }, [fail]);
    return (
        <p ref={fail} className="fail">
            An error occured. Please try again
        </p>
    );
};

const SignUp = () => {
    const [result, setResult] = useState(false);
    const [fail, setFail] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_yl46qeq",
                "template_dmvltip",
                e.target,
                "user_dxqPyhKeMBRRD5AieGFSs"
            )
            .then((res) => {
                console.log(res);
                setResult(true);
            })
            .catch((err) => {
                console.log(err);
                setFail(true);
            });
        e.target.reset();
    };

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="exit"
            variants={pageTransitionFull}
            className="signupContainer"
        >
            <motion.div variants={pageTransition}>
                <Link to="/">
                    <button className="button button--mimas">
                        <span>Go Back</span>
                    </button>
                </Link>
                <motion.div className="formContainer">
                    <div className="formTitle">Register</div>
                    <form onSubmit={sendEmail}>
                        <div className="user-details">
                            <div className="input-box">
                                <span className="formDetails">Full Name</span>
                                <input
                                    type="text"
                                    name="FirstName"
                                    placeholder="First Name"
                                    required
                                />
                            </div>
                            <div className="input-box">
                                <span className="formDetails">Last Name</span>
                                <input
                                    type="text"
                                    name="LastName"
                                    placeholder="Last Name"
                                    required
                                />
                            </div>
                            <div className="input-box">
                                <span className="formDetails">Email</span>
                                <input
                                    type="text"
                                    name="EmailAddress"
                                    placeholder="EmailAddress"
                                    required
                                />
                            </div>
                            <div className="input-box">
                                <span className="formDetails">
                                    Phone Number
                                </span>
                                <input
                                    type="text"
                                    name="PhoneNumber"
                                    placeholder="Phone Number"
                                    required
                                />
                            </div>
                            {/* <div>
                                <span className="formDetails">
                                    Upload Resume
                                </span>
                                <input type="file" name="file" required />
                            </div> */}
                        </div>
                        <div className="button-one">
                            <input type="submit" value="Subscibe" />
                        </div>
                    </form>
                </motion.div>
                <div className="row">{result ? <Result /> : null}</div>
                <div className="row">{fail ? <FailedResult /> : null}</div>
            </motion.div>
        </motion.div>
    );
};

export default SignUp;
