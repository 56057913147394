import React from "react";
import "./index.css";
import { motion } from "framer-motion";
// import arrow from "../../assets/CurlyArrow.svg";
// import blob from "../../assets/orangeBlob.svg";
import { Link } from "react-router-dom";
import image1 from "../../assets/compresspng/image1.jpeg";
import image2 from "../../assets/compresspng/image2.jpeg";
import image3 from "../../assets/compresspng/experienceBackground.jpeg";
import image4 from "../../assets/compresspng/image4.jpeg";
import image5 from "../../assets/compresspng/image5.jpeg";
import image6 from "../../assets/compresspng/image6.jpeg";
import { Send } from "react-feather";

const pageTransitionFull = {
    initial: {
        x: "100%",
        opacity: 0.6,
    },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.6,
            when: "beforeChildren",
        },
    },
    exit: {
        x: "100%",
        opacity: 0.6,
        transition: {
            duration: 1,
            when: "afterChildren",
        },
    },
};
const Help = () => {
    return (
        <motion.div
            variants={pageTransitionFull}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <div className="help-holder">
                <div className="container">
                    <div id="wrap" className="help">
                        <div className="help-content">
                            <section className="process">
                                <div className="container">
                                    <div className="wrapper">
                                        <div className="start">
                                            <ul className="row">
                                                <li>
                                                    <div className="left one">
                                                        <div className="icon">
                                                            <img
                                                                src={image1}
                                                                alt="a woman on a laptop"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="media-body uno">
                                                        <h4>
                                                            Subscribe To our
                                                            free career advice
                                                        </h4>
                                                        <p>
                                                            "Our weekly
                                                            newsletter provides
                                                            practical tips for a
                                                            successful job
                                                            search."
                                                        </p>
                                                        <div className="media-body-number">
                                                            <h1>01</h1>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="left two">
                                                        <div className="icon">
                                                            <img
                                                                src={image2}
                                                                alt="a man and a woman looking at a clipboard"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="media-body dos">
                                                        <h4>
                                                            Tell us about your
                                                            job search issues
                                                        </h4>
                                                        <p>
                                                            "We in turn create a
                                                            specialized program
                                                            for you."
                                                        </p>
                                                        <div className="media-body-number">
                                                            <h1>02</h1>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="left three">
                                                        <div className="icon">
                                                            <img
                                                                src={image3}
                                                                alt="men looking at a blueprint"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="media-body tres">
                                                        <h4>
                                                            We Create a
                                                            professional online
                                                            presence
                                                        </h4>
                                                        <p>
                                                            "Your profile will
                                                            represent your
                                                            unique selling
                                                            points and
                                                            competencies."
                                                        </p>
                                                        <div className="media-body-number">
                                                            <h1>03</h1>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="left four">
                                                        <div className="icon">
                                                            <img
                                                                src={image4}
                                                                alt="a woman on a laptop"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="media-body quatro">
                                                        <h4>
                                                            we create a unique
                                                            cover letter &
                                                            resume
                                                        </h4>
                                                        <p>
                                                            "This is aimed at
                                                            demonstrating your
                                                            strengths to your
                                                            potential employer
                                                            whilst representing
                                                            your true self."
                                                        </p>
                                                        <div className="media-body-number">
                                                            <h1>04</h1>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="left five">
                                                        <div className="icon">
                                                            <img
                                                                src={image5}
                                                                alt="a woman looking at a laptop and taking notes"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="media-body cinco">
                                                        <h4>
                                                            direct interview
                                                            coaching
                                                        </h4>
                                                        <p>
                                                            "We give you your
                                                            confidence to
                                                            represent your core
                                                            competencies,
                                                            abilities and growth
                                                            path."
                                                        </p>
                                                        <div className="media-body-number">
                                                            <h1>05</h1>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="left six">
                                                        <div className="icon">
                                                            <img
                                                                src={image6}
                                                                alt="a man giving career advice to an individual"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="media-body sies">
                                                        <h4>
                                                            We create your
                                                            online interview
                                                            space
                                                        </h4>
                                                        <p>
                                                            "This gives you the
                                                            modern mode of a
                                                            confident self
                                                            presentation."
                                                        </p>
                                                        <div className="media-body-number">
                                                            <h1>06</h1>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>

                    <a
                        href="mailto:nextcareertt@gmail.com?subject=Evaluation Email&body=Upload Documents Here"
                        className="callta-one"
                        style={{
                            zIndex: "100",
                            padding: "2em",
                            marginBottom: "2rem",
                            width: "280px",
                        }}
                    >
                        <span>Take Evaluation</span>
                        <Send width="30" height="20" />
                    </a>
                    <Link to="/">
                        <button className="button button--mimas">
                            <span>Go Back</span>
                        </button>
                    </Link>
                </div>
            </div>
        </motion.div>
    );
};

export default Help;
