import React from "react";
import { Send } from "react-feather";
import "./index.css";
import image2 from "../../assets/undraw_term_sheet.svg";

const CTA = () => {
    return (
        <div className="white-background">
            <div className="container">
                <div className="wrapper h-100">
                    <div className="f-col">
                        <div className="cta-text">
                            <h1 className="cta-text-h1">
                                Take free evaluation
                                <br />
                                <span>now</span>
                            </h1>
                        </div>
                        <a
                            href="mailto:nextcareertt@gmail.com?subject=Evaluation Email&body=Upload Documents Here"
                            className="callta-one"
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                zIndex: "100",
                                padding: "2rem",
                                marginBottom: "2rem",
                                width: "280px",
                            }}
                        >
                            <span>Take Evaluation</span>
                            <Send width="30" height="20" />
                        </a>
                        <div className="eval-image">
                            <img src={image2} alt="" />
                        </div>
                        <p>
                            We create your online interview space, we create a
                            unique cover letter & resume, We create a
                            professional online presence, we provide direct
                            interview coaching
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CTA;
