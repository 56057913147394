import React from "react";
import landing from "../../assets/compresspng/resources.PNG";
import video from "../../assets/jeruelVid.mp4";
import "./index.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const moveRight = {
    initial: {
        x: "100%",
        opacity: 0.6,
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1.2,
            when: "beforeChildren",
            ease: [0.6, 0.01, -0.05, 0.95],
        },
    },
    exit: {
        x: "100%",
        opacity: [1, 0.8, 0.6],
        transition: {
            duration: 1.6,
            when: "afterChildren",
        },
    },
};

const itemMain = {
    initial: {
        opacity: 0,
        y: -400,
        scale: 1.3,
    },
    animate: {
        opacity: 1,
        y: 0,
        scale: [1.3, 1.2, 1],
        transition: {
            ease: [0.6, 0.01, -0.05, 0.95],
            duration: 1.6,
            originX: 0,
        },
    },
    exit: {
        scale: 1.5,
    },
};

const containerVariant = {
    initial: {
        opacity: 0,
        y: 200,
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            stiffness: 120,
            delay: 2,
        },
    },
    exit: {
        opacity: 0,
        y: -200,
    },
};
const Resources = () => {
    return (
        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={moveRight}
            className="resources"
        >
            <div className="resources-title">
                <motion.div
                    variants={itemMain}
                    style={{ overflow: "hidden" }}
                    className="resources-title-image"
                >
                    <img src={landing} alt="devices and other materials" />
                </motion.div>
                <div className="resources-title-text">
                    <h1>Resources</h1>
                </div>
            </div>
            <motion.div variants={containerVariant} className="container">
                <div className="resources-desc">
                    <video controls autoPlay loop>
                        <source src={video} type="video/mp4" />
                    </video>
                    <Link to="/">
                        <button className="button button--mimas">
                            <span>Go Back</span>
                        </button>
                    </Link>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default Resources;
