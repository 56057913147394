import React, { useEffect } from "react";
import landing from "../../assets/compresspng/buildingdark.png";
import "./index.scss";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import about1 from "../../assets/compresspng/about1.jpeg";
import about2 from "../../assets/compresspng/about2.jpeg";
import about3 from "../../assets/compresspng/about3.jpeg";
import { PieChart, Users, Eye } from "react-feather";

const moveRight = {
    initial: {
        x: "100%",
        opacity: 0.6,
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1.2,
            when: "beforeChildren",
            ease: [0.6, 0.01, -0.05, 0.95],
        },
    },
    exit: {
        x: "100%",
        opacity: [1, 0.8, 0.6],
        transition: {
            duration: 1.6,
            when: "afterChildren",
        },
    },
};

const itemMain = {
    initial: {
        opacity: 0,
        y: -400,
        scale: 1.3,
    },
    animate: {
        opacity: 1,
        y: 0,
        scale: [1.3, 1.2, 1],
        transition: {
            ease: [0.6, 0.01, -0.05, 0.95],
            duration: 1.6,
            originX: 0,
        },
    },
    exit: {
        scale: 1.5,
    },
};

const containerVariant = {
    initial: {
        opacity: 0,
        y: 200,
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            stiffness: 120,
            delay: 2,
        },
    },
    exit: {
        opacity: 0,
        y: -200,
    },
};
const Resources = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    return (
        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={moveRight}
            className="resources"
        >
            <div className="about-title">
                <motion.div
                    variants={itemMain}
                    style={{ overflow: "hidden" }}
                    className="about-title-image"
                >
                    <img src={landing} alt="Next Career's Logo" />
                </motion.div>
                <div className="about-title-text">
                    <h1>About Us</h1>
                </div>
            </div>
            <motion.div variants={containerVariant} className="container">
                <div className="about-desc">
                    <ul className="behind-text">
                        <li>
                            Over the last ten years Economic forces created
                            conditions that forced Many organizations to resort
                            to short-term employment strategies.
                        </li>
                        <li>
                            This saw the developing trend of Contract-Based
                            Employment which meant that the labor the market
                            needed to be ready to face fast-changing labour
                            requirements.
                        </li>
                        <li>
                            These emerging employment strategies created much
                            confusion for many working professionals who
                            ultimately did not know how to champion their own
                            job search, leading to many situations of Employment
                            Shock.
                        </li>
                        <li>
                            NextCareerTT Ltd was formed to help persons in the
                            labor market to produce the best representation of
                            themselves as they pursue employment opportunities.
                            We give such persons the confidence in themselves
                            and the quality of their representation, thereby
                            empowering persons to be resistant through any
                            turbulence in the Labour Market.
                        </li>
                    </ul>
                    {/* <p className="behind-text">
                        <ChevronRight /> Over the last ten years Economic forces
                        created conditions that forced Many organizations to
                        resort to short-term employment strategies. <br />
                        <ChevronRight /> This saw the developing trend of
                        Contract-Based Employment which meant that the labor the
                        market needed to be ready to face fast-changing labour
                        requirements. <br />
                        <ChevronRight /> These emerging employment strategies
                        created much confusion for many working professionals
                        who ultimately did not know how to champion their own
                        job search, leading to many situations of Employment
                        Shock. <br />
                        <ChevronRight /> NextCareerTT Ltd was formed to help
                        persons in the labor market to produce the best
                        representation of themselves as they pursue employment
                        opportunities. We give such persons the confidence in
                        themselves and the quality of their representation,
                        thereby empowering persons to be resistant through any
                        turbulence in the Labour Market.
                    </p> */}
                    <div className="mission">
                        <div
                            data-aos="fade-right"
                            className="mission-three main-mission"
                        >
                            <div className="about-image-holder">
                                <img src={about3} alt="a man on a laptop" />
                                <div className="mission-icon">
                                    <Eye />
                                </div>
                            </div>
                            <h2>Our Vision</h2>
                            <p>
                                To be seen as the leading source of Employment
                                advise and career guidance in the region.
                            </p>
                        </div>
                        <hr />
                        <div
                            data-aos="fade-right"
                            className="mission-two main-mission"
                        >
                            <div className="about-image-holder">
                                <img
                                    src={about2}
                                    alt="Persons looking at a technical drawing"
                                />
                                <div className="mission-icon">
                                    <PieChart />
                                </div>
                            </div>
                            <h2>Our Mission</h2>
                            <p>
                                To equip our client with the confidence and
                                ability to successfully represent themselves in
                                the job market.
                            </p>
                        </div>
                        <hr />
                        <div
                            data-aos="fade-right"
                            className="mission-one main-mission"
                        >
                            <div className="about-image-holder">
                                <img
                                    src={about1}
                                    alt="A woman searching through books"
                                />
                                <div className="mission-icon">
                                    <Users />
                                </div>
                            </div>
                            <h2>Our Core Values</h2>
                            <ul>
                                <li>
                                    NextCareerTT Ltd is grounded on our work
                                    ethos that guides the way we do business.
                                </li>
                                <li>
                                    We strongly believe in seeing the value of
                                    everyone and the contribution they make to
                                    our wider society.
                                </li>
                                <li>
                                    The dignity of every situation is important
                                    to us and therefore no customer shall ever
                                    feel unappreciated.
                                </li>
                                <li>
                                    NextCareerTT Ltd fully recognize that each
                                    person is different and such differences
                                    bring a unique perspective that must be
                                    captured in your career search.
                                </li>
                            </ul>
                        </div>
                        <hr />
                    </div>
                    <Link to="/">
                        <button className="button button--mimas">
                            <span>Go Back</span>
                        </button>
                    </Link>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default Resources;
