import React from "react";
import "./index.css";

const SectionHeader = ({ title, white }) => {
    return (
        <>
            {/* <div className="content__item">
                <h1 className="link link--leda" data-text={title}>
                    <span>{title}</span>
                </h1>
            </div> */}
            <div className="section-header">
                <h1 style={white ? { color: "white" } : { color: "black" }}>
                    {title}
                </h1>
            </div>
        </>
    );
};

export default SectionHeader;
