import React, { Component } from "react";
import "./index.scss";
import SectionHeader from "../../components/SectionHeader";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                background: "black",
                borderRadius: "100%",
            }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                background: "black",
                borderRadius: "100%",
            }}
            onClick={onClick}
        />
    );
}

function SampleNextArrowMobile(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "none",
                background: "black",
                borderRadius: "100%",
            }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrowMobile(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "none",
                background: "black",
                borderRadius: "100%",
            }}
            onClick={onClick}
        />
    );
}

export default class Testimonials extends Component {
    render() {
        const settings = {
            slidesToShow: 3,
            slidesToScroll: 1,
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "100px",
            speed: 1000,
            swipeToSlide: true,
            mobileFirst: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            autoplay: true,
            autoplaySpeed: 3000,
            cssEase: "linear",

            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        dots: true,
                        nextArrow: <SampleNextArrow />,
                        prevArrow: <SamplePrevArrow />,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        dots: true,
                        nextArrow: <SampleNextArrow />,
                        prevArrow: <SamplePrevArrow />,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        nextArrow: <SampleNextArrowMobile />,
                        prevArrow: <SamplePrevArrowMobile />,
                    },
                },
            ],
        };
        return (
            <div>
                <SectionHeader white title="Testimonials" />
                <div className="container">
                    <div className="testimonial">
                        <Slider {...settings}>
                            <div className="testimonial__slide">
                                <h3 className="testimonial__content">
                                    <div className="testimonial__quote">
                                        &quot;
                                    </div>
                                    For the first time I can remember the
                                    details on my resume.
                                </h3>
                            </div>
                            <div className="testimonial__slide">
                                <h3 className="testimonial__content">
                                    <div className="testimonial__quote">
                                        &quot;
                                    </div>
                                    I finally understand how to prepare for an
                                    interview.
                                </h3>
                            </div>
                            <div className="testimonial__slide">
                                <h3 className="testimonial__content">
                                    <div className="testimonial__quote">
                                        &quot;
                                    </div>
                                    I was always nervous for interviews until
                                    this intervention, thank you NextCareerTT.
                                </h3>
                            </div>
                            <div className="testimonial__slide">
                                <h3 className="testimonial__content">
                                    <div className="testimonial__quote">
                                        &quot;
                                    </div>
                                    For the first time I am sending our job
                                    applications and I am getting calls for
                                    interviews.
                                </h3>
                            </div>
                            <div className="testimonial__slide">
                                <h3 className="testimonial__content">
                                    <div className="testimonial__quote">
                                        &quot;
                                    </div>
                                    I aced my interview, thank you so much for
                                    all your help.
                                </h3>
                            </div>
                            <div className="testimonial__slide">
                                <h3 className="testimonial__content">
                                    <div className="testimonial__quote">
                                        &quot;
                                    </div>
                                    Anyone looking for a job, please talk to
                                    these guys.
                                </h3>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        );
    }
}
