import React from "react";
import { Carousel } from "react-bootstrap";

import image from "../../assets/compresspng/2-min.jpeg";
import image2 from "../../assets/compresspng/5-min.jpeg";
// import image3 from "../../assets/compresspng/7-min.PNG";
import "./index.css";

const Slider = () => {
    return (
        <div id="home">
            <Carousel
                className="carousel-container"
                fade={true}
                interval={6000}
                slide={true}
                touch={true}
                indicators={false}
            >
                <Carousel.Item as="div">
                    <img
                        className="d-block w-100"
                        src={image}
                        height={250}
                        alt="a man discusing career advice with a woman"
                    />
                    <Carousel.Caption>
                        <h3>We Get You Hired </h3>
                        <p>with our custom made programs.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={image2}
                        height={250}
                        alt="two women looking at papers"
                    />

                    <Carousel.Caption>
                        <h3>We Focus on You</h3>
                        <p>and getting that job you want.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                {/* <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={image3}
                        height={250}
                        alt="Third slide"
                    />

                    <Carousel.Caption>
                        <h3>Third slide label</h3>
                        <p>
                            Praesent commodo cursus magna, vel scelerisque nisl
                            consectetur.
                        </p>
                    </Carousel.Caption>
                </Carousel.Item> */}
            </Carousel>
        </div>
    );
};

export default Slider;
