import React, { lazy, Suspense } from "react";
import { motion } from "framer-motion";
import Landing from "../../sections/Landing";
import Testimonials from "../../sections/Testimonial";
import HowItWorks from "../../sections/HowItWorks";

const Header = lazy(() => import("../../components/Header"));
const Footer = lazy(() => import("../../components/Footer"));
const Features = lazy(() => import("../../sections/Features"));
const WhyUs = lazy(() => import("../../sections/WhyChooseUs"));
const FAQ = lazy(() => import("../../sections/FAQ"));
const About = lazy(() => import("../../sections/About"));
const Contact = lazy(() => import("../../sections/Contact"));

const homeAnimation = {
    initial: {
        x: "100%",
        opacity: 0.6,
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1.2,
            when: "beforeChildren",
            ease: [0.6, 0.01, -0.05, 0.95],
        },
    },
    exit: {
        x: "100%",
        opacity: [1, 0.8, 0.6],
        transition: {
            duration: 1.6,
            ease: [0.6, 0.01, -0.05, 0.95],
        },
    },
};

const HomeScreen = () => {
    return (
        <motion.div
            variants={homeAnimation}
            initial="initial"
            animate="animate"
            exit="exit"
            id="main-container"
        >
            <Suspense fallback={null}>
                <Header />
                <Landing />
                <HowItWorks />
                <WhyUs />
                <Features />
                <Testimonials />
                <About />
                <FAQ />
                <Contact />
                <Footer />
            </Suspense>
        </motion.div>
    );
};

export default HomeScreen;
