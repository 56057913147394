import React, { useEffect } from "react";
import "./index.css";
import { motion } from "framer-motion";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";
import landing from "../../assets/compresspng/experience.PNG";
import Aos from "aos";
import "aos/dist/aos.css";

const moveRight = {
    initial: {
        x: "100%",
        opacity: 0.6,
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1.2,
            when: "beforeChildren",
            ease: [0.6, 0.01, -0.05, 0.95],
        },
    },
    exit: {
        x: "100%",
        opacity: [1, 0.8, 0.6],
        transition: {
            duration: 1.6,
            when: "afterChildren",
        },
    },
};

const itemMain = {
    initial: {
        opacity: 0,
        y: -400,
        scale: 1.3,
    },
    animate: {
        opacity: 1,
        y: 0,
        scale: [1.3, 1.2, 1],
        transition: {
            ease: [0.6, 0.01, -0.05, 0.95],
            duration: 1.6,
            originX: 0,
        },
    },
    exit: {
        scale: 1.5,
    },
};

const containerVariant = {
    initial: {
        opacity: 0,
        y: 200,
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            stiffness: 120,
            delay: 2,
        },
    },
    exit: {
        opacity: 0,
        y: -200,
    },
};
const Resources = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    return (
        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={moveRight}
            className="experience"
        >
            <div className="experience-title">
                <motion.div
                    variants={itemMain}
                    style={{ overflow: "hidden" }}
                    className="experience-title-image"
                >
                    <img src={landing} alt="an experience individual" />
                </motion.div>
                <div className="experience-title-text">
                    <h1>Experience</h1>
                </div>
            </div>
            <motion.div variants={containerVariant} className="container">
                <div className="experience-desc">
                    <p>
                        The Management Team of NextCareerTT Ltd. Comes with over
                        seventeen (17) years of direct industry level
                        experience. Such has allowed our clients to benefit from
                        professional experiences that have designed,
                        spearheaded, and revolutionized the interview and
                        recruitment process for ultimate success. NextCareerTT
                        Ltd serves to bring you inside boardroom experience with
                        practical tips, know-how, and presentation skills to get
                        you your next job.
                    </p>
                    <div className="experience-numbers">
                        <div>
                            <div
                                data-aos="fade-up"
                                className="experience-numbers-card"
                            >
                                <h4 className="experience-numbers-card-heading">
                                    <VisibilitySensor
                                        partialVisibility
                                        offset={{ bottom: 200 }}
                                    >
                                        {({ isVisible }) => (
                                            <div style={{ height: 100 }}>
                                                {isVisible ? (
                                                    <div>
                                                        <CountUp
                                                            start={600}
                                                            end={1000}
                                                            duration={5}
                                                        />
                                                        +
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </VisibilitySensor>
                                </h4>
                                <p className="experience-numbers-card-text">
                                    Careers & Resumes Revived
                                </p>
                            </div>
                        </div>
                        <div>
                            <div
                                data-aos="fade-up"
                                className="experience-numbers-card"
                            >
                                <h4 className="experience-numbers-card-heading">
                                    <VisibilitySensor
                                        partialVisibility
                                        offset={{ bottom: 200 }}
                                    >
                                        {({ isVisible }) => (
                                            <div style={{ height: 100 }}>
                                                {isVisible ? (
                                                    <div>
                                                        <CountUp
                                                            start={10}
                                                            end={17}
                                                            duration={4}
                                                        />
                                                        +
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </VisibilitySensor>
                                </h4>
                                <p className="experience-numbers-card-text">
                                    Years of Experience
                                </p>
                            </div>
                        </div>
                        <div>
                            <div
                                data-aos="fade-up"
                                className="experience-numbers-card"
                            >
                                <h4 className="experience-numbers-card-heading">
                                    <VisibilitySensor
                                        partialVisibility
                                        offset={{ bottom: 200 }}
                                    >
                                        {({ isVisible }) => (
                                            <div style={{ height: 100 }}>
                                                {isVisible ? (
                                                    <div>
                                                        <CountUp
                                                            start={700}
                                                            end={1000}
                                                            duration={6}
                                                        />
                                                        +
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </VisibilitySensor>
                                </h4>
                                <p className="experience-numbers-card-text">
                                    Satisfied Customers
                                </p>
                            </div>
                        </div>
                    </div>
                    <Link to="/#about">
                        <button className="button button--mimas">
                            <span>Go Back</span>
                        </button>
                    </Link>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default Resources;
