const { createGlobalStyle } = require("styled-components");

export const GlobalStyle = createGlobalStyle`
:root{
    /* Colors */
    --background: #eff7f9;
    --black:#0a0b10;
    --purple:#803bec;
    --pink:#e5a1f8;
    --white:#fff;
    --nav:#35353f;
    --nav2:#3f3d56;
    --blue: #0e5ca2;
    --analagous: #83d0d4;

    --darkViolet: #fff;
    --grayishBlue: hsl(216, 30%, 68%);

    --veryDarkViolet: #83A7D4;
    --darkGrayishViolet: #f5a42d;
    --veryLightGray: hsl(0, 0%, 98%);
}

*,*::before,*::after{
    margin:0;
    padding:0;
    box-sizing:border-box;
    font-family: 'Poppins', sans-serif;
}
html{
  scroll-behavior:smooth;
}
    body,
    html,
    a {
        // font-family: 'Poppins', sans-serif;
            }
    body {

        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background-color: rgba(0,0,0,0.97);

        overflow-x: hidden;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin:0;
        padding:0;
    }
    a {

        text-decoration: none;
        outline: none;
    }
    button{
        border:none;
        outline:none;
        &:focus{
            outline:none;
        }
    }

    *:focus {
        outline: none;
    }

    img{
        width: 100%;
        height: auto;
    }

`;
