import { useEffect } from "react";
import HomeScreen from "./screens/Home";
import SignUp from "./screens/SignUp";
import Resources from "./screens/Resources";
import Experience from "./screens/Experience";
import Satisfaction from "./screens/Satisfaction";
import Services from "./screens/Services";
import "./App.css";
import { Switch, Route, useLocation } from "react-router-dom";
import { AnimatePresence, useMotionValue, motion } from "framer-motion";
import { GlobalStyle } from "./GlobalStyles";
import About from "./screens/About";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import CTA from "./screens/CTA";
import WhatsApp from "./components/WhatsApp";

function App() {
    const location = useLocation();
    // const [cursorXY, setCursorXY] = useState({ x: -100, y: -100 });
    const cursorX = useMotionValue(-100);
    const cursorY = useMotionValue(-100);

    useEffect(() => {
        const moveCursor = (e) => {
            // const x = e.clientX - 16;
            // const y = e.clientY - 16;
            cursorX.set(e.clientX - 16);
            cursorY.set(e.clientY - 16);
            // setCursorXY({ x, y });
        };
        window.addEventListener("mousemove", moveCursor);
        return () => {
            window.removeEventListener("mousemove", moveCursor);
        };
    }, [cursorX, cursorY]);
    return (
        <>
            <motion.div
                className="cursor"
                // style={{
                //     transform: `translate3d(${cursorXY.x}px, ${cursorXY.y}px, 0)`,
                // }}
                style={{
                    translateX: cursorX,
                    translateY: cursorY,
                }}
            />
            <GlobalStyle />
            <AnimatePresence exitBeforeEnter>
                <ScrollToTop>
                    <Switch location={location} key={location.key}>
                        <Route path="/" exact component={HomeScreen} />
                        <Route path="/signup" exact component={SignUp} />
                        <Route
                            path="/experience"
                            exact
                            component={Experience}
                        />
                        <Route path="/resources" exact component={Resources} />
                        <Route
                            path="/customers"
                            exact
                            component={Satisfaction}
                        />
                        <Route path="/howitworks" exact component={Services} />
                        <Route path="/about" exact component={About} />
                        <Route path="/free-evaluation" exact component={CTA} />
                    </Switch>
                    <WhatsApp />
                </ScrollToTop>
            </AnimatePresence>
        </>
    );
}

export default App;
