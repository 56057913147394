import React from "react";
import "./index.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import landing from "../../assets/compresspng/smilingwoman.PNG";

const moveRight = {
    initial: {
        x: "100%",
        opacity: 0.6,
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1.2,
            when: "beforeChildren",
            ease: [0.6, 0.01, -0.05, 0.95],
        },
    },
    exit: {
        x: "100%",
        opacity: [1, 0.8, 0.6],
        transition: {
            duration: 1.6,
            when: "afterChildren",
        },
    },
};

const itemMain = {
    initial: {
        opacity: 0,
        y: -400,
        scale: 1.3,
    },
    animate: {
        opacity: 1,
        y: 0,
        scale: [1.3, 1.2, 1],
        transition: {
            ease: [0.6, 0.01, -0.05, 0.95],
            duration: 1.6,
            originX: 0,
        },
    },
    exit: {
        scale: 1.5,
    },
};

const containerVariant = {
    initial: {
        opacity: 0,
        y: 200,
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            stiffness: 120,
            delay: 2,
        },
    },
    exit: {
        opacity: 0,
        y: -200,
    },
};
const Resources = () => {
    return (
        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={moveRight}
            className="satisfaction"
        >
            <div className="satisfaction-title">
                <motion.div
                    variants={itemMain}
                    style={{ overflow: "hidden" }}
                    className="satisfaction-title-image"
                >
                    <img src={landing} alt="a very satisfied customer" />
                </motion.div>
                <div className="satisfaction-title-text">
                    <h1>Customer</h1>
                    <h1>Satisfaction</h1>
                </div>
            </div>
            <motion.div variants={containerVariant} className="container">
                <div className="satisfaction-desc">
                    <p>
                        The NextCareerTT Ltd Value proposition:
                        <br />
                        Our value proposition is bounded by an in-touch
                        relationship with our clients where we encourage
                        meaningful communication along with a culture that is
                        built on positive support. Our benefits to you are:
                        <br />
                        1. Our one-to-one approach to business allows us to
                        understand our client’s needs whilst allowing the client
                        to understand their employers’ needs. <br />
                        2. We give you exactly what you need to be successful by
                        tailoring your resume, cover letter, and your interview
                        preparation based on your unique self. <br />
                        3. We take the guesswork out of the Career Search
                        process by giving you the tools and techniques to get it
                        done. <br />
                        4. We give you confidence by using what you already know
                        to represent you.
                    </p>
                    <Link to="/">
                        <button className="button button--mimas">
                            <span>Go Back</span>
                        </button>
                    </Link>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default Resources;
